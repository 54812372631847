@font-face {
    font-family: AlliancePlatt;
    src: url('AlliancePlatt-Light.otf');
    font-weight: 300
}
@font-face {
    font-family: AlliancePlatt;
    src: url('AlliancePlatt-Regular.otf');
    font-weight: 400
}
@font-face {
    font-family: AlliancePlatt;
    src: url('AlliancePlatt-Medium.otf');
    font-weight: 500
}
@font-face {
    font-family: AlliancePlatt;
    src: url('AlliancePlatt-SemiBold.otf');
    font-weight: 600
}
